export const EXERCISES = {
  categoryList: ["Cardio", "Core", "FullBody", "LowerBody", "UpperBody"],
  categoryDefinitions: {
    LowerBody: {
      displayName: "Lower Body",
      exercises: [
        "BodyweightSquats",
        "WalkingLunges",
        "StepUps",
        "SquatJumps",
        "HighKnees",
        "CalfRaises",
      ],
    },
    UpperBody: {
      displayName: "Upper Body",
      exercises: ["Dips", "DumbbellRows", "PushUps"],
    },
    Cardio: {
      displayName: "Cardio",
      exercises: ["Burpees", "JumpingJacks", "JumpRope"],
    },
    Core: {
      displayName: "Core",
      exercises: [
        "Bicycles",
        "Crunches",
        "FlutterKicks",
        "Plank",
        "Situps",
        "SquatThrusts",
      ],
    },
    FullBody: {
      displayName: "Full Body",
      exercises: ["BearCrawl", "MountainClimbers"],
    },
  },
  allExercises: {
    SitUps: {
      id: "SitUps",
      name: "Sit Ups",
      description: "",
      category: "Core",
      status: "ACTIVE",
    },
    Crunches: {
      id: "Crunches",
      name: "Crunches",
      description: "",
      category: "Core",
      status: "ACTIVE",
    },
    Bicycles: {
      id: "Bicycles",
      name: "Bicycles",
      description: "",
      category: "Core",
      status: "ACTIVE",
    },
    Plank: {
      id: "Plank",
      name: "Plank",
      description: "",
      category: "Core",
      status: "ACTIVE",
    },
    FlutterKicks: {
      id: "FlutterKicks",
      name: "Flutter Kicks",
      description: "",
      category: "Core",
      status: "ACTIVE",
    },
    SquatThrusts: {
      id: "SquatThrusts",
      name: "Squat Thrusts",
      description: "",
      category: "Core",
      status: "ACTIVE",
    },

    BodyweightSquats: {
      id: "BodyweightSquats",
      name: "Squats (Bodyweight)",
      description: "",
      category: "LowerBody",
      status: "ACTIVE",
    },
    WalkingLunges: {
      id: "WalkingLunges",
      name: "Lunges (Walking)",
      description: "",
      category: "LowerBody",
      status: "ACTIVE",
    },
    SquatJumps: {
      id: "SquatJumps",
      name: "Squat Jumps",
      description: "",
      category: "LowerBody",
      status: "ACTIVE",
    },
    HighKnees: {
      id: "HighKnees",
      name: "High Knees",
      description: "",
      category: "LowerBody",
      status: "ACTIVE",
    },
    CalfRaises: {
      id: "CalfRaises",
      name: "Calf Raises",
      description: "",
      category: "LowerBody",
      status: "ACTIVE",
    },
    StepUps: {
      id: "StepUps",
      name: "Step Ups",
      description: "",
      category: "LowerBody",
      status: "ACTIVE",
    },

    JumpingJacks: {
      id: "JumpingJacks",
      name: "Jumping Jacks",
      description: "",
      category: "Cardio",
      status: "ACTIVE",
    },
    Burpees: {
      id: "Burpees",
      name: "Burpees",
      description: "",
      category: "Cardio",
      status: "ACTIVE",
    },
    JumpRope: {
      id: "JumpRope",
      name: "Jump Rope",
      description: "",
      category: "Cardio",
      status: "ACTIVE",
    },

    PushUps: {
      id: "PushUps",
      name: "Push Ups",
      description: "",
      category: "UpperBody",
      status: "ACTIVE",
    },
    Dips: {
      id: "Dips",
      name: "Dips",
      description: "",
      category: "UpperBody",
      status: "ACTIVE",
    },
    DumbbellRows: {
      id: "DumbbellRows",
      name: "Dumbbell Rows",
      description: "",
      category: "UpperBody",
      status: "ACTIVE",
    },

    BearCrawl: {
      id: "BearCrawl",
      name: "Bear Crawl",
      description: "",
      category: "FullBody",
      status: "ACTIVE",
    },
    MountainClimbers: {
      id: "MountainClimbers",
      name: "Mountain Climber",
      description: "",
      category: "FullBody",
      status: "ACTIVE",
    },
  },
};

export const TEST_SELECTED_EXERCISES = [
  "PushUp",
  "SitUp",
  "BodyweightSquat",
  "WalkingLunge",
  "StepUp",
];

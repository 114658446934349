import {
  PATH_DASHBOARD,
  PATH_REGISTER,
  PATH_REGISTER_CONFIRM,
  PATH_ADD_ROUTINE,
  PATH_LOG_WORKOUT,
  PATH_UPDATE_ROUTINE,
  PATH_ACTIVITY_LOG,
} from "./paths";

import Register from "./register/Register";
import RegisterConfirm from "./register/RegisterConfirm";
import Dashboard from "./dashboard/index";
import UpdateRoutine from "./routine/UpdateRoutine";
import Workout from "./workout/index";
import WorkoutLog from "./workout/WorkoutLog";

const APP_ROUTES = [
  {
    path: PATH_REGISTER,
    exact: true,
    key: "register",
    protected: false,
    component: Register,
  },
  {
    path: PATH_REGISTER_CONFIRM,
    exact: true,
    key: "register-confirm",
    protected: false,
    component: RegisterConfirm,
  },
  {
    path: PATH_DASHBOARD,
    exact: true,
    key: "dashboard",
    protected: true,
    component: Dashboard,
  },
  {
    path: PATH_ADD_ROUTINE,
    exact: true,
    key: "add-routine",
    protected: true,
    component: UpdateRoutine,
  },
  {
    path: PATH_UPDATE_ROUTINE,
    exact: true,
    key: "update-routine",
    protected: true,
    component: UpdateRoutine,
  },
  {
    path: PATH_LOG_WORKOUT,
    exact: true,
    key: "log-workout",
    protected: true,
    component: Workout,
  },
  {
    path: PATH_ACTIVITY_LOG,
    exact: true,
    key: "activity-log",
    protected: true,
    component: WorkoutLog,
  },
];

export default APP_ROUTES;

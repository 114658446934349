import React from "react";

import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
}));

const Wrapper = ({ children, maxWidth = "xs" }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth={maxWidth} className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>{children}</div>
    </Container>
  );
};

export default Wrapper;

import React from "react";
import { useSelector } from "react-redux";

import SelectExerciseCategoryGroup from "./SelectExerciseCategoryGroup";

const SelectExerciseList = (props) => {
  const { categoryList, exercises, categoryDefinitions } = useSelector(
    (state) => state.exercise
  );
  const { selectedExercises, handleToggle } = props;

  return (
    <>
      {categoryList.map((categoryId) => {
        return (
          <SelectExerciseCategoryGroup
            key={categoryId}
            allExercises={exercises}
            categoryId={categoryId}
            categoryDefinition={categoryDefinitions[categoryId]}
            selectedExercises={selectedExercises}
            handleToggle={handleToggle}
          />
        );
      })}
    </>
  );
};

export default SelectExerciseList;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { STATE_FINISHED, STATE_WORKING } from "../store/store-constants";
import { PATH_ADD_ROUTINE } from "../paths";
import { deleteRoutine as actionDeleteRoutine } from "../store/routine-store";

import Spinner from "../common/Spinner";
import NoResultsFound from "../common/NoResultsFound";
import SmallCapsHeading from "../common/SmallCapsHeading";
import { LinkButton } from "../common/Buttons";
// import DeleteRoutine from "../routine/DeleteRoutine";
import DeleteDialog from "../common/DeleteDialog";

import DashboardSection from "./DashboardSection";
import RoutineCard from "./RoutineCard";

const MyRoutines = () => {
  const reduxDispatch = useDispatch();
  const { myRoutines, myRoutinesIdList, workingState } = useSelector(
    (state) => state.routine
  );

  const [routineToDelete, setRoutineToDelete] = useState(null);

  const onDeleteClick = (routineId) => {
    setRoutineToDelete(myRoutines[routineId]);
  };

  const onOkToDeleteClick = () => {
    reduxDispatch(actionDeleteRoutine(routineToDelete.id));
    setRoutineToDelete(null);
  };

  const onCancelDeleteClick = () => {
    setRoutineToDelete(null);
  };

  return (
    <DashboardSection>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <SmallCapsHeading>My Routines</SmallCapsHeading>
        </Grid>
        <Grid item>
          {workingState === STATE_FINISHED && (
            <LinkButton
              path={PATH_ADD_ROUTINE}
              buttonText="New Routine"
              buttonIcon={<AddOutlinedIcon />}
              variant="text"
              color="primary"
            />
          )}
        </Grid>
      </Grid>
      {workingState === STATE_WORKING && <Spinner />}
      {workingState === STATE_FINISHED && myRoutinesIdList.length === 0 && (
        <NoResultsFound>You haven't created any routines.</NoResultsFound>
      )}
      {workingState === STATE_FINISHED &&
        myRoutinesIdList &&
        myRoutinesIdList.length > 0 &&
        myRoutinesIdList.map((routineId) => {
          return (
            <RoutineCard
              key={routineId}
              routine={myRoutines[routineId]}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      {routineToDelete && (
        <DeleteDialog
          title={`Delete ${routineToDelete.name}`}
          confirmButtonText="Yes, Delete"
          onConfirm={onOkToDeleteClick}
          cancelButtonText="Cancel"
          onCancel={onCancelDeleteClick}
        >
          You're about to delete routine <b>{routineToDelete.name}</b>.<br />
          All existing workouts and related stats will also be removed. <br />
          <br />
          Are you sure you want to delete this routine?
        </DeleteDialog>
      )}
    </DashboardSection>
  );
};

export default MyRoutines;

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  submitCancelGroup: {
    textAlign: "right",
    marginTop: "16px",
  },
  cancelButton: {
    marginRight: "10px",
  },
}));

export const buttonStyles = makeStyles((theme) => ({
  btn: {
    fontSize: ".7rem",
    marginRight: "6px",
  },
}));

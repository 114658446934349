import { API } from "aws-amplify";

export const apiName = "protectedEndpoint";
export const pathPrefixAccount = "/accounts";
export const pathPrefixRoutine = "/routines";
export const pathPrefixWorkout = "/workouts";

// helper methods:
const doPost = (url, formData) => {
  return API.post(apiName, url, { body: formData });
};

const doPatch = (url, formData) => {
  return API.patch(apiName, url, { body: formData });
};

// const doPut = (url, formData) => {
//   return API.put(apiName, url, { body: formData });
// };

const doGet = (url, querystringParams = null) => {
  return API.get(apiName, url, { queryStringParameters: querystringParams });
};

const doDelete = (url) => {
  return API.del(apiName, url);
};

export const createAccount = () => {
  return doPost(pathPrefixAccount);
};

export const fetchAccount = () => {
  return doGet(pathPrefixAccount);
};

export const createWorkout = (workoutData) => {
  return doPost(pathPrefixWorkout, workoutData);
};

export const fetchWorkoutStats = () => {
  return doGet(`${pathPrefixWorkout}/stats`);
};

export const fetchWorkouts = (start, end = null) => {
  return doGet(pathPrefixWorkout, { start: start, end: end });
};

export const deleteWorkout = (workoutId) => {
  return doDelete(`${pathPrefixWorkout}/${workoutId}`);
};

export const fetchRoutine = (routineId) => {
  return doGet(`${pathPrefixRoutine}/${routineId}`);
};

export const updateRoutine = (routineId, routineData) => {
  return doPatch(`${pathPrefixRoutine}/${routineId}`, routineData);
};

export const fetchAccountRoutines = () => {
  return doGet(pathPrefixRoutine);
};

export const createRoutine = (routineData) => {
  return doPost(pathPrefixRoutine, routineData);
};

export const deleteRoutine = (routineId) => {
  return doDelete(`${pathPrefixRoutine}/${routineId}`);
};

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { PATH_SIGN_IN } from "../paths";
import { signOut } from "../utils/auth";

const SignOut = ({ setIsSignedIn }) => {
  const [isWorking, setIsWorking] = useState(true);

  useEffect(() => {
    const doSignOut = async () => {
      await signOut();
      setIsSignedIn(false);
      setIsWorking(false);
    };
    doSignOut();
  }, [setIsSignedIn]);

  if (!isWorking) {
    return <Redirect to={PATH_SIGN_IN} />;
  }

  return <></>;
};

export default SignOut;

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import reduxThunk from "redux-thunk";

import { appReducer } from "./app-store";
import { exerciseReducer } from "./exercise-store";
import { routineReducer } from "./routine-store";
import { workoutReducer } from "./workout-store";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  app: appReducer,
  exercise: exerciseReducer,
  routine: routineReducer,
  workout: workoutReducer,
});

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

export default store;

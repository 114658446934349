import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ActionButton } from "../common/Buttons";

const DeleteDialog = ({
  title,
  children,
  confirmButtonText,
  onConfirm,
  cancelButtonText,
  onCancel,
}) => {
  return (
    <Dialog
      open={true}
      onClose={() => onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton
          buttonText={cancelButtonText}
          onClickEvent={() => onCancel()}
          color="primary"
        />
        <ActionButton
          buttonText={confirmButtonText}
          onClickEvent={() => onConfirm()}
          color="secondary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;

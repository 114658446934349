import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";

import SelectExerciseListItem from "./SelectExerciseListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  categoryHead: {
    marginTop: "10px",
    marginBottom: "0",
    fontWeight: 500,
  },
}));

const SelectExerciseCategoryGroup = (props) => {
  const {
    allExercises,
    selectedExercises,
    categoryId,
    categoryDefinition,
    handleToggle,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Typography component="h5" variant="h5" className={classes.categoryHead}>
        {categoryDefinition.displayName} Exercises:
      </Typography>
      <List key={categoryId} className={classes.root}>
        {categoryDefinition.exercises.map((exerciseId) => {
          return (
            <SelectExerciseListItem
              key={exerciseId}
              exercise={allExercises[exerciseId]}
              handleToggle={handleToggle}
              isChecked={
                selectedExercises &&
                selectedExercises.indexOf(exerciseId) !== -1
              }
            />
          );
        })}
      </List>
      <Divider />
    </>
  );
};

export default SelectExerciseCategoryGroup;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import { ActionButton } from "../common/Buttons";

function getCircuitArray(itemCount) {
  return [...Array(itemCount).keys()];
}

function getWorkoutDateAsLocal(dt) {
  return dayjs(dt).format("dddd, MMM DD YYYY (h:mm a)");
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingBottom: "0",
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: "0",
  },
  title: {
    fontWeight: "500",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const WorkoutSummary = ({ routine, workout, onDeleteClick }) => {
  const { exercises: allExercises } = useSelector((state) => state.exercise);
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Typography variant="body1" className={classes.title}>
            {routine.name}
          </Typography>
        }
        subheader={
          <Typography variant="body2">
            {getWorkoutDateAsLocal(workout.createdOn)}
          </Typography>
        }
      />
      <CardContent className={classes.cardContent}>
        <TableContainer>
          <Table aria-label="Workout Summary" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Circuit</TableCell>
                {getCircuitArray(Object.keys(workout.circuitData).length).map(
                  (circuit) => {
                    return (
                      <TableCell align="center" key={`circuit:${circuit + 1}`}>
                        {circuit + 1}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {workout.exercises.map((exercise) => {
                return (
                  <TableRow key={`row:${exercise.exerciseId}`}>
                    <TableCell>
                      {exercise.sequence}.{" "}
                      {allExercises[exercise.exerciseId].name}
                    </TableCell>
                    {getCircuitArray(
                      Object.keys(workout.circuitData).length
                    ).map((circuit) => {
                      return (
                        <TableCell
                          align="center"
                          key={`cell:${circuit + 1}:${exercise.exerciseId}`}
                        >
                          {
                            workout.circuitData[circuit + 1][
                              exercise.exerciseId
                            ]
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ textAlign: "right" }}>
          <ActionButton
            color="secondary"
            buttonIcon={<DeleteOutlineOutlinedIcon />}
            buttonText="Delete"
            variant="text"
            onClickEvent={() => onDeleteClick(workout.id)}
          ></ActionButton>
        </div>
      </Collapse>
    </Card>
  );
};

export default WorkoutSummary;

import NoResultsFound from "../common/NoResultsFound";
import WorkoutSummary from "./WorkoutSummary";

const WorkoutList = ({
  workoutIdList,
  workouts,
  myRoutines,
  onDeleteClick,
}) => {
  if (!workoutIdList || 0 === workoutIdList.length) {
    console.log("no workouts");
    return <NoResultsFound>No workouts found.</NoResultsFound>;
  } else {
    return workoutIdList.map((workoutId) => {
      const workout = workouts[workoutId];
      return (
        <WorkoutSummary
          key={workoutId}
          workout={workout}
          routine={myRoutines[workout.routineId]}
          onDeleteClick={onDeleteClick}
        />
      );
    });
  }
};

export default WorkoutList;

import {
  fetchAccountRoutines,
  createRoutine as apiCreateRoutine,
  updateRoutine as apiUpdateRoutine,
  deleteRoutine as apiDeleteRoutine,
} from "../utils/api-requests";

import { setFeedbackMessage } from "./app-store";
import {
  STATE_IDLE,
  STATE_WORKING,
  STATE_FINISHED,
  STATE_ERROR,
} from "./store-constants";

export const ROUTINE_FETCH_ALL = "ROUTINE_FETCH_ALL_ROUTINES";
export const ROUTINE_SET_WORKING_STATE = "ROUTINE_SET_WORKING_STATE";
export const ROUTINE_UPDATE_EXISTING = "ROUTINE_UPDATE_EXISTING";
export const ROUTINE_ADD_NEW = "ROUTINE_ADD_NEW";
export const ROUTINE_DELETE = "ROUTINE_DELETE";

const ROUTINE_INITIAL_STATE = {
  workingState: STATE_IDLE,
  myRoutinesIdList: [],
  myRoutines: {},
  workoutStats: {},
  isLoaded: false,
};

export const setWorkingState = (workingState = STATE_WORKING) => {
  return {
    type: ROUTINE_SET_WORKING_STATE,
    payload: {
      workingState: workingState,
    },
  };
};

export const getMyRoutines = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setWorkingState(STATE_WORKING));
      const routines = await fetchAccountRoutines();

      // construct an array of the routine id's to maintain the sort order
      // and also a routine lookup object for fast retrieval
      const routinesIdList = [];
      const routineLookup = {};

      if (routines && routines.length > 0) {
        routines.forEach((routine) => {
          routinesIdList.push(routine.id);
          routineLookup[routine.id] = routine;
        });
      }

      dispatch({
        type: ROUTINE_FETCH_ALL,
        payload: {
          myRoutines: routineLookup,
          myRoutinesIdList: routinesIdList,
          isLoaded: true,
          workingState: STATE_FINISHED,
        },
      });
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const addRoutine = (routineData) => {
  return async (dispatch, getState) => {
    dispatch(setWorkingState(STATE_WORKING));
    try {
      const newRoutine = await apiCreateRoutine(routineData);
      dispatch(setFeedbackMessage("Routine created", false));
      dispatch({
        type: ROUTINE_ADD_NEW,
        payload: {
          routine: newRoutine,
          workingState: STATE_FINISHED,
        },
      });
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const updateRoutine = (routineId, routineData) => {
  return async (dispatch, getState) => {
    dispatch(setWorkingState(STATE_WORKING));
    try {
      await apiUpdateRoutine(routineId, routineData);
      dispatch(setFeedbackMessage("Routine updated", false));
      dispatch({
        type: ROUTINE_UPDATE_EXISTING,
        payload: {
          routineId: routineId,
          routine: routineData,
          workingState: STATE_FINISHED,
        },
      });
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const deleteRoutine = (routineId) => {
  return async (dispatch, getState) => {
    dispatch(setWorkingState(STATE_WORKING));
    try {
      await apiDeleteRoutine(routineId);
      dispatch(setFeedbackMessage("Routine deleted", false));
      dispatch({
        type: ROUTINE_DELETE,
        payload: {
          routineId: routineId,
          workingState: STATE_FINISHED,
        },
      });
      dispatch(setFeedbackMessage("Routine deleted", false));
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const routineReducer = (state = ROUTINE_INITIAL_STATE, action) => {
  switch (action.type) {
    case ROUTINE_UPDATE_EXISTING:
      const newUpdateState = {
        ...state,
        workingState: action.payload.workingState,
      };
      newUpdateState.myRoutines[action.payload.routineId] =
        action.payload.routine;
      // TODO: check of the ordering of the routines needs to be changed
      return newUpdateState;

    case ROUTINE_ADD_NEW:
      const newAddState = {
        ...state,
        workingState: action.payload.workingState,
      };
      newAddState.myRoutines[action.payload.routine.id] =
        action.payload.routine;
      newAddState.myRoutinesIdList.push(action.payload.routine.id);

      // TODO: re-order the myRoutineIdList based on the name of the newly create routine
      return newAddState;

    case ROUTINE_DELETE:
      const deleteState = {
        ...state,
        workingState: action.payload.workingState,
      };
      delete deleteState.myRoutines[action.payload.routineId];
      var idx = deleteState.myRoutinesIdList.indexOf(action.payload.routineId);
      if (idx !== -1) {
        deleteState.myRoutinesIdList.splice(idx, 1);
      }

      return deleteState;

    case ROUTINE_SET_WORKING_STATE:
    case ROUTINE_FETCH_ALL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

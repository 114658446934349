import { Auth } from "aws-amplify";

const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_API_ENDPOINT,
} = process.env;

// development:
export const amplify_auth_config = {
  region: REACT_APP_COGNITO_REGION,
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
};

export const amplify_api_config = {
  endpoints: [
    {
      name: "protectedEndpoint",
      endpoint: REACT_APP_API_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
};

import short from "short-uuid";
import qs from "qs";

export const getShortUUID = () => {
  return short.generate();
};

export function formatNumber(num) {
  return new Intl.NumberFormat().format(num);
}

export function extractQueryString(queryString) {
  if (!queryString) {
    return {};
  }

  if (queryString.charAt(0) === "?") {
    queryString = queryString.slice(1);
  }

  return qs.parse(queryString);
}
import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const queryFormat = "YYYY-MM-DDTHH:mm:ss";

export function getUtcStartOfWeek() {
  const sow = dayjs().startOf("week");
  const utcStartOfWeek = dayjs(sow.format()).utc();
  return utcStartOfWeek.format(queryFormat);
}

export function getUtcStartOfMonth() {
  const som = dayjs().startOf("month");
  const utcStartOfMonth = dayjs(som.format()).utc();
  return utcStartOfMonth.format(queryFormat);
}

// State transitions:
export const STATE_WORKING = "WORKING";
export const STATE_IDLE = "IDLE";
export const STATE_FINISHED = "FINISHED";
export const STATE_ERROR = "ERROR";



// Exercise Types


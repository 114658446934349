import React, { useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";

import { setFeedbackMessage } from "../store/app-store";
import { PATH_SIGN_IN } from "../paths";
import { confirmRegistrationSignUp } from "../utils/auth";
import RegisterConfirmForm from "./RegisterConfirmForm";
import PageHeading from "../common/PageHeading";

const RegisterConfirm = () => {
  const reduxDispatch = useDispatch();
  const [isWorking, setIsWorking] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const onConfirmSubmit = ({ email, confirmCode }) => {
    const doConfirm = async () => {
      try {
        setIsWorking(true);
        await confirmRegistrationSignUp(email, confirmCode);
        reduxDispatch(
          setFeedbackMessage(
            "Registration confirmed. For security reasons, please sign in.",
            false
          )
        );
        setRedirect(PATH_SIGN_IN);
        setIsConfirmed(true);
      } catch (err) {
        reduxDispatch(setFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    doConfirm();
  };

  if (!isWorking && isConfirmed && redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <PageHeading>Confirm Registration</PageHeading>
      <Typography component="p" variant="body2" gutterBottom>
        You should have received an email containing a confirmation code.
      </Typography>
      <RegisterConfirmForm onSubmit={onConfirmSubmit} />
      <Divider style={{ marginBottom: "16px" }} />
      <Typography component="p" variant="body2" gutterBottom>
        <Link to={PATH_SIGN_IN} component={RouterLink}>
          Have an account? Sign In.
        </Link>
      </Typography>
    </>
  );
};

export default RegisterConfirm;

import {
  fetchWorkoutStats,
  createWorkout as apiCreateWorkout,
  fetchWorkouts as apiFetchWorkouts,
  deleteWorkout as apiDeleteWorkout,
} from "../utils/api-requests";
import { setFeedbackMessage } from "./app-store";
import {
  STATE_IDLE,
  STATE_WORKING,
  STATE_FINISHED,
  STATE_ERROR,
} from "./store-constants";

export const WORKOUT_STATS_FETCH = "WORKOUT_STATS_FETCH";
export const WORKOUT_SET_WORKING_STATE = "WORKOUT_SET_WORKING_STATE";
export const WORKOUT_CREATE = "WORKOUT_CREATE";
export const WORKOUT_MY_WORKOUTS = "WORKOUT_MY_WORKOUTS";
export const WORKOUT_DELETE = "WORKOUT_DELETE";

const WORKOUT_INITIAL_STATE = {
  workingState: STATE_IDLE,
  stats: null,
  isLoaded: false,
  myWorkouts: {},
  myWorkoutsIdList: [],
};

export const setWorkingState = (workingState = STATE_WORKING) => {
  return {
    type: WORKOUT_SET_WORKING_STATE,
    payload: {
      workingState: workingState,
    },
  };
};

export const getSummaryStats = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setWorkingState(STATE_WORKING));
      const workoutStats = await fetchWorkoutStats();
      dispatch({
        type: WORKOUT_STATS_FETCH,
        payload: {
          workingState: STATE_FINISHED,
          stats: workoutStats,
          isLoaded: true,
        },
      });
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const createWorkout = (workoutData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setWorkingState(STATE_WORKING));
      await apiCreateWorkout(workoutData);
      dispatch({
        type: WORKOUT_CREATE,
        payload: {
          workingState: STATE_FINISHED,
          isLoaded: false, // so the dashboard will refresh next time it's loaded
        },
      });
      dispatch(setFeedbackMessage("Workout logged", false));
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const getWorkouts = (start, end) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setWorkingState(STATE_WORKING));
      const workouts = await apiFetchWorkouts(start, end);

      const workoutsIdList = [];
      const workoutsLookup = {};

      if (workouts && workouts.length > 0) {
        workouts.forEach((workout) => {
          workoutsIdList.push(workout.id);
          workoutsLookup[workout.id] = workout;
        });
      }

      dispatch({
        type: WORKOUT_MY_WORKOUTS,
        payload: {
          myWorkouts: workoutsLookup,
          myWorkoutsIdList: workoutsIdList,
          workingState: STATE_FINISHED,
        },
      });
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const deleteWorkout = (workoutId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setWorkingState(STATE_WORKING));
      await apiDeleteWorkout(workoutId);
      dispatch({
        type: WORKOUT_DELETE,
        payload: {
          workingState: STATE_FINISHED,
          workoutId: workoutId,
          isLoaded: false, // will cause a refresh of the stats
        },
      });
      dispatch(setFeedbackMessage("Workout deleted", false));
    } catch (err) {
      dispatch(setFeedbackMessage(err.message, true));
      dispatch(setWorkingState(STATE_ERROR));
    }
  };
};

export const workoutReducer = (state = WORKOUT_INITIAL_STATE, action) => {
  switch (action.type) {
    case WORKOUT_DELETE:
      const deleteState = {
        ...state,
        workingState: action.payload.workingState,
        isLoaded: action.payload.isLoaded,
      };
      delete deleteState.myWorkouts[action.payload.workoutId];
      var idx = deleteState.myWorkoutsIdList.indexOf(action.payload.workoutId);
      if (idx !== -1) {
        deleteState.myWorkoutsIdList.splice(idx, 1);
      }
      return deleteState;
    case WORKOUT_CREATE:
    case WORKOUT_SET_WORKING_STATE:
    case WORKOUT_STATS_FETCH:
    case WORKOUT_MY_WORKOUTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

import React from "react";
import { Link as RouterLink } from "react-router-dom";

import clsx from "clsx";
import FitnessCenterOutlinedIcon from "@material-ui/icons/FitnessCenterOutlined";
import DirectionsRunOutlinedIcon from "@material-ui/icons/DirectionsRunOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { LinkButton } from "../common/Buttons";
import RoutineCardExercisePanel from "./RoutineCardExercisePanel";
import {
  PATH_LOG_WORKOUT,
  PATH_PARAM_ROUTINE_ID,
  PATH_UPDATE_ROUTINE,
} from "../paths";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingBottom: "0",
  },
  start: {
    marginLeft: "auto",
  },
  title: {
    fontWeight: "500",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  exercisePanel: {
    paddingTop: "0",
    marginTop: "0",
  },
}));

const RoutineCard = ({ routine, onDeleteClick }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const logWorkoutUrl = PATH_LOG_WORKOUT.replace(
    PATH_PARAM_ROUTINE_ID,
    routine.id
  );

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <FitnessCenterOutlinedIcon />
          </Avatar>
        }
        title={
          <Typography variant="body1" className={classes.title}>
            {routine.name}
          </Typography>
        }
        //subheader={`${workoutsThisMonth || 0} workouts this month`}
        action={
          <IconButton
            component={RouterLink}
            to={logWorkoutUrl}
            title="workout!"
          >
            <DirectionsRunOutlinedIcon />
          </IconButton>
        }
        className={classes.cardHeader}
      />
      <CardActions disableSpacing style={{ marginLeft: "50px" }}>
        <LinkButton
          buttonText="edit"
          buttonIcon={<EditOutlinedIcon fontSize="small" />}
          path={PATH_UPDATE_ROUTINE.replace(PATH_PARAM_ROUTINE_ID, routine.id)}
          variant="text"
          color="primary"
        />
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <RoutineCardExercisePanel
          routine={routine}
          onDeleteClick={onDeleteClick}
        />
      </Collapse>
    </Card>
  );
};

export default RoutineCard;

import Typography from "@material-ui/core/Typography";

const ParagraphText = ({ children, variant = "body1" }) => {
  return (
    <Typography component="p" variant={variant} gutterBottom>
      {children}
    </Typography>
  );
};

export default ParagraphText;

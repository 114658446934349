import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const ExerciseListItem = (props) => {
  const { exercise, isChecked, handleToggle } = props;

  if (!exercise) {
    return null;
  }

  const labelId = `exercise-list-${exercise.id}`;

  return (
    <ListItem role={undefined} dense button onClick={handleToggle(exercise.id)}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={isChecked}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={exercise.name} />
    </ListItem>
  );
};

export default ExerciseListItem;

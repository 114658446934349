import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearFeedbackMessage } from "../store/app-store";

import SuccessMessage from "./SuccessMessage";
import ErrorMessage from "./ErrorMessage";

const FeedbackMessageController = () => {
  const reduxDispatch = useDispatch();
  const { feedbackMessage } = useSelector((state) => state.app);

  const clearMessage = () => {
    reduxDispatch(clearFeedbackMessage());
  };

  if (feedbackMessage) {
    if (feedbackMessage.isError) {
      return (
        <ErrorMessage
          messageText={feedbackMessage.message}
          onDismissClick={clearMessage}
        />
      );
    } else {
      return (
        <SuccessMessage
          messageText={feedbackMessage.message}
          onDismissClick={clearMessage}
        />
      );
    }
  }

  return null;
};

export default FeedbackMessageController;

import { Redirect, Route, Switch } from "react-router-dom";

import SignIn from "../signin/SignIn";
import SignOut from "../signout/index";
import Error404 from "./Error404";
import ProtectedRoute from "./ProtectedRoute";
import { PATH_SIGN_IN, PATH_SIGN_OUT } from "../paths";

const RenderRoutes = ({
  routes,
  defaultRedirectPath,
  isSignedIn,
  setIsSignedIn,
  setCurrentUser,
  // setBreadcrumbs,
}) => {
  return (
    <Switch>
      <Route path={PATH_SIGN_IN} exact={true}>
        <SignIn
          key="sign-in"
          isSignedIn={isSignedIn}
          setIsSignedIn={setIsSignedIn}
          setCurrentUser={setCurrentUser}
        />
      </Route>
      <Route path={PATH_SIGN_OUT} exact={true}>
        <SignOut key="sign-out" setIsSignedIn={setIsSignedIn} />
      </Route>
      {routes.map((route) => {
        if (route.protected) {
          return (
            <ProtectedRoute
              path={route.path}
              exact={route.exact}
              key={route.key}
              component={route.component}
              isSignedIn={isSignedIn}
              // setBreadcrumbs={setBreadcrumbs}
            />
          );
        } else {
          const Component = route.component;
          return (
            <Route path={route.path} exact={route.exact} key={route.key}>
              <Component />
            </Route>
          );
        }
      })}
      <Route path="/404">
        <Error404 />
      </Route>
      <Redirect to={defaultRedirectPath} />
    </Switch>
  );
};

export default RenderRoutes;

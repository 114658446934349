import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ThemeProvider } from "@material-ui/core/styles";

import { checkSignIn, signOut } from "./utils/auth";
import { APP_THEME } from "./utils/theme";

import { setExercises } from "./store/exercise-store";
import { getMyRoutines } from "./store/routine-store";
import { PATH_SIGN_IN } from "./paths";
import APP_ROUTES from "./routes-config";
import RenderRoutes from "./common/RenderRoutes";
import Header from "./common/Header";
import FeedbackMessageController from "./common/FeedbackMessageController";
// import AppBreadcrumbs from "./common/AppBreadcrumbs";
import Wrapper from "./common/Wrapper";

const App = () => {
  const reduxDispatch = useDispatch();

  const [isWorking, setIsWorking] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  // const [crumbs, setCrumbs] = useState(null);

  useEffect(() => {
    const checkIsSignedIn = async () => {
      const result = await checkSignIn();
      setIsSignedIn(result.isSignedIn);
      setCurrentUser(result.user);
      setIsWorking(false);

      // make sure we always load the routines
      if (result.isSignedIn) {
        reduxDispatch(getMyRoutines());
      }
    };
    checkIsSignedIn();
    reduxDispatch(setExercises());
  }, [reduxDispatch]);

  const onSignOutClick = () => {
    const doSignOut = async () => {
      signOut();
      setIsSignedIn(false);
      setCurrentUser(null);
    };
    doSignOut();
  };

  if (!isWorking) {
    return (
      <ThemeProvider theme={APP_THEME}>
        <Router>
          <Header
            isSignedIn={isSignedIn}
            currentUser={currentUser}
            onSignOutClick={onSignOutClick}
          />
          <Wrapper>
            {/* <AppBreadcrumbs crumbs={crumbs} /> */}
            <FeedbackMessageController />
            <RenderRoutes
              routes={APP_ROUTES}
              defaultRedirectPath={PATH_SIGN_IN}
              isSignedIn={isSignedIn}
              setIsSignedIn={setIsSignedIn}
              setCurrentUser={setCurrentUser}
              // setBreadcrumbs={setCrumbs}
            />
          </Wrapper>
        </Router>
      </ThemeProvider>
    );
  }

  return <></>;
};

export default App;

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import ButtonGroup from "../common/ButtonGroup";
import { SubmitButton, LinkButton, ActionButton } from "../common/Buttons";
import PageSubHeading from "../common/PageSubHeading";
import ParagraphText from "../common/ParagraphText";

import DraggableExerciseCard from "./DraggableExerciseCard";

const UpdateRoutineForm = ({
  selectedExercises,
  routineData,
  repData,
  onSubmit,
  dragEnded,
  onBackClick,
  onRepChange,
  onFieldChange,
  isWorking,
}) => {
  const { exercises } = useSelector((state) => state.exercise);

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <TextField
        id="name"
        name="name"
        label="Name"
        variant="standard"
        fullWidth
        margin="normal"
        size="small"
        helperText="Provide a name for the routine"
        autoFocus
        required
        onBlur={(e) => onFieldChange(e.target.id, e.target.value)}
        defaultValue={routineData.name}
      />
      <TextField
        id="description"
        name="description"
        label="Description"
        multiline
        rows={2}
        variant="standard"
        fullWidth
        margin="normal"
        size="small"
        helperText="Optional. Provide a description or notes for the routine."
        onBlur={(e) => onFieldChange(e.target.id, e.target.value)}
        defaultValue={routineData.description}
      />
      <TextField
        id="circuits"
        name="circuits"
        label="Number of Circuits"
        variant="standard"
        fullWidth
        type="number"
        margin="normal"
        size="small"
        helperText="The number of circuits in this routine"
        onBlur={(e) => onFieldChange(e.target.id, e.target.value)}
        defaultValue={routineData.circuits}
      />
      <PageSubHeading>Exercises</PageSubHeading>
      <ParagraphText variant="body2">
        Enter the rep counts for each exercise below. To re-order the exercises
        you can drag using the drag icon and drop it to the desired spot.
      </ParagraphText>
      <DragDropContext onDragEnd={dragEnded}>
        <Droppable droppableId="dropArea">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {selectedExercises.map((exerciseId, index) => {
                return (
                  <Draggable
                    key={exerciseId}
                    draggableId={exerciseId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <DraggableExerciseCard
                          key={exerciseId}
                          exercise={exercises[exerciseId]}
                          isDragging={snapshot.isDragging}
                          dragHandleProps={provided.dragHandleProps}
                          onFieldChange={onRepChange}
                          defaultValue={repData[exerciseId]}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Grid container justify="space-between" alignContent="center">
        <Grid item>
          <ButtonGroup>
            <ActionButton
              onClickEvent={onBackClick}
              buttonText="< Back"
              isDisabled={isWorking}
            />
          </ButtonGroup>
        </Grid>
        <Grid item>
          <ButtonGroup>
            <LinkButton path="/" buttonText="cancel" isDisabled={isWorking} />{" "}
            <SubmitButton
              isWorking={isWorking}
              isDisabled={isWorking}
              buttonText="Save"
            />
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateRoutineForm;

import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import { EXERCISES } from "../data/exercises";

import { ActionButton } from "../common/Buttons";

const useStyles = makeStyles((theme) => ({
  exercisePanel: {
    paddingTop: "0",
    marginTop: "0",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: ".8rem",
    margin: "2px",
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  legendRoot: {
    display: "flex",
    alignItems: "center",
  },
  deleteButton: {
    marginLeft: "auto",
    fontSize: ".7rem",
  },
}));

const RoutineCardExercisePanel = ({ routine, onDeleteClick }) => {
  const classes = useStyles();

  return (
    <CardContent className={classes.exercisePanel}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{routine.circuits} circuits of:</TableCell>
            <TableCell align="center">Reps</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {routine.exercises.map((exercise) => {
            return (
              <TableRow key={exercise.exerciseId}>
                <TableCell>
                  {exercise.sequence}.{" "}
                  {EXERCISES.allExercises[exercise.exerciseId].name}
                </TableCell>
                <TableCell align="center">{exercise.reps}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div style={{ textAlign: "right", marginTop: "16px" }}>
        <ActionButton
          color="secondary"
          buttonIcon={<DeleteOutlineOutlinedIcon />}
          buttonText="Delete"
          variant="text"
          onClickEvent={() => onDeleteClick(routine.id)}
        ></ActionButton>
      </div>
    </CardContent>
  );
};

export default RoutineCardExercisePanel;

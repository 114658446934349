import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import WorkoutList from "./WorkoutList";
import Spinner from "../common/Spinner";
import PageHeading from "../common/PageHeading";
import PageSubHeading from "../common/PageSubHeading";
import { getWorkouts, deleteWorkout } from "../store/workout-store";
import { STATE_FINISHED, STATE_WORKING } from "../store/store-constants";
import DeleteDialog from "../common/DeleteDialog";

import { getUtcStartOfMonth, getUtcStartOfWeek } from "../utils/date-helpers";
import { extractQueryString } from "../utils/general-helpers";

const WorkoutLog = () => {
  const reduxDispatch = useDispatch();
  const { search } = useLocation();
  const { myWorkouts, myWorkoutsIdList, workingState } = useSelector(
    (state) => state.workout
  );
  const { myRoutines, workingState: routineWorkingState } = useSelector(
    (state) => state.routine
  );
  const [subTitle, setSubTitle] = useState("");
  const [workoutToDelete, setWorkoutToDelete] = useState(null);

  useEffect(() => {
    const qsParams = extractQueryString(search);

    if (qsParams.hasOwnProperty("ts")) {
      if (qsParams.ts === "tw") {
        setSubTitle("This Week");
        reduxDispatch(getWorkouts(getUtcStartOfWeek()));
      } else if (qsParams.ts === "tm") {
        setSubTitle("This Month");
        reduxDispatch(getWorkouts(getUtcStartOfMonth()));
      }
    }
  }, [search, reduxDispatch]);

  const onDeleteClick = (workoutId) => {
    setWorkoutToDelete(workoutId);
  };

  const onOkToDeleteClick = () => {
    reduxDispatch(deleteWorkout(workoutToDelete));
    setWorkoutToDelete(null);
  };

  const onCancelDeleteClick = () => {
    setWorkoutToDelete(null);
  };

  return (
    <>
      <PageHeading>Workout Log</PageHeading>
      <PageSubHeading>{subTitle}</PageSubHeading>
      {workingState === STATE_WORKING && <Spinner />}
      {workingState === STATE_FINISHED &&
        routineWorkingState === STATE_FINISHED && (
          <WorkoutList
            workouts={myWorkouts}
            workoutIdList={myWorkoutsIdList}
            myRoutines={myRoutines}
            onDeleteClick={onDeleteClick}
          />
        )}
      {workoutToDelete && (
        <DeleteDialog
          title="Delete Workout"
          confirmButtonText="Yes, Delete"
          onConfirm={onOkToDeleteClick}
          cancelButtonText="Cancel"
          onCancel={onCancelDeleteClick}
        >
          Removing this workout will adjust your stats accordingly.
          <br />
          Are you sure you want to delete this workout?
        </DeleteDialog>
      )}
    </>
  );
};

export default WorkoutLog;

import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "45px",
  },
  titleLink: {
    textDecoration: "none",
    color: "#FFFFFF",
  },
}));

const Header = ({ isSignedIn, currentUser, onSignOutClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar className={classes.toolbar}>
          <PostAddOutlinedIcon />
          <Typography variant="body1" className={classes.title}>
            <Link to={"/"} className={classes.titleLink}>
              Workout Log
            </Link>
          </Typography>

          {isSignedIn && (
            <Button
              color="inherit"
              startIcon={<ExitToAppOutlinedIcon />}
              onClick={() => onSignOutClick()}
              size="small"
            >
              Sign Out
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;

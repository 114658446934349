import React, { useState, useEffect } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { createAccount } from "../utils/api-requests";

import PageHeading from "../common/PageHeading";

import { PATH_DASHBOARD, PATH_REGISTER, PATH_FORGOT_PASSWORD } from "../paths";
import {
  signIn,
  checkSignIn,
  updateAccountCreatedParam,
  getCurrentUser,
} from "../utils/auth";

import { setFeedbackMessage } from "../store/app-store";

import SignInForm from "./SignInForm";

const SignIn = (props) => {
  const reduxDispatch = useDispatch();

  const { isSignedIn, setIsSignedIn, setCurrentUser } = props;

  const [doingSignInCheck, setDoingSignInCheck] = useState(true);

  useEffect(() => {
    const checkIfUserSignedIn = async () => {
      setDoingSignInCheck(true);
      let result = await checkSignIn();
      setIsSignedIn(result.isSignedIn);
      setCurrentUser(result.user);
      setDoingSignInCheck(false);
    };
    checkIfUserSignedIn();
  }, [setIsSignedIn, setCurrentUser]);

  const onSubmit = ({ email, password }) => {
    const doSignIn = async () => {
      const result = await signIn(email, password);
      if (result.error) {
        reduxDispatch(setFeedbackMessage(result.error, true));
      } else {
        if (
          result &&
          result.isSignedIn &&
          result.user["custom:account_created"] === "N"
        ) {
          await createAccount();
          await updateAccountCreatedParam();
          result.user = await getCurrentUser();
        }
        setIsSignedIn(result.isSignedIn);
        setCurrentUser(result.user);
      }
    };
    doSignIn();
  };

  if (!doingSignInCheck && isSignedIn) {
    return <Redirect to={PATH_DASHBOARD} />;
  }

  if (!doingSignInCheck && !isSignedIn) {
    return (
      <>
        <PageHeading>Sign In</PageHeading>
        <SignInForm onSubmit={onSubmit} />
        <Divider style={{ marginBottom: "16px" }} />
        <Typography component="p" variant="body2" gutterBottom>
          <Link to={PATH_FORGOT_PASSWORD} component={RouterLink}>
            Forgot Password?
          </Link>
        </Typography>
        <Typography component="p" variant="body2" gutterBottom>
          <Link to={PATH_REGISTER} component={RouterLink}>
            Need an account? Register here.
          </Link>
        </Typography>
      </>
    );
  }

  return <></>;
};

export default SignIn;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMyRoutines } from "../store/routine-store";
import { getSummaryStats } from "../store/workout-store";

import MyRoutines from "./MyRoutines";
import WorkoutStats from "./WorkoutStats";

const Dashboard = () => {
  const reduxDispatch = useDispatch();
  const { isLoaded: routinesLoaded } = useSelector((state) => state.routine);
  const { isLoaded: statsLoaded } = useSelector((state) => state.workout);

  useEffect(() => {
    if (!routinesLoaded) {
      reduxDispatch(getMyRoutines());
    }

    if (!statsLoaded) {
      reduxDispatch(getSummaryStats());
    }
  }, [routinesLoaded, statsLoaded, reduxDispatch]);

  return (
    <>
      <WorkoutStats />
      <MyRoutines />
    </>
  );
};

export default Dashboard;

import DragHandleIcon from "@material-ui/icons/DragHandle";

const DragHandle = ({ dragHandleProps }) => {
  return (
    <div
      style={{
        height: "24px",
        width: "24px",
      }}
      {...dragHandleProps}
    >
      <DragHandleIcon />
    </div>
  );
};

export default DragHandle;

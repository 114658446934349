export const PATH_PARAM_ROUTINE_ID = ":routineId";
export const PATH_PARAM_EXERCISE_ID = ":exerciseId";
export const PATH_PARAM_WORKOUT_ID = ":workoutId";

export const PATH_SIGN_IN = "/sign-in";
export const PATH_SIGN_OUT = "/sign-out";

export const PATH_REGISTER = "/register";
export const PATH_REGISTER_CONFIRM = "/register-confirmation";
export const PATH_REGISTER_CONFIRM_RESEND_CODE =
  "/resend-register-confirmation-code";

export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_FORGOT_PASSWORD_RESEND_CODE = "/resend-forgot-password-code";

export const PATH_DASHBOARD = "/";

export const PATH_ADD_ROUTINE = "/routine/create";
export const PATH_UPDATE_ROUTINE = `/routine/${PATH_PARAM_ROUTINE_ID}/update`;

export const PATH_LOG_WORKOUT = `/routine/${PATH_PARAM_ROUTINE_ID}/log-workout`;

export const PATH_ACTIVITY_LOG = "/activity-log";

import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    fontSize: ".8rem",
    marginRight: "6px",
  },
}));

export const ActionButton = ({
  onClickEvent,
  buttonText,
  color = "primary",
  variant = "contained",
  isDisabled = false,
  buttonIcon = null,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      size="medium"
      variant={variant}
      color={color}
      onClick={onClickEvent}
      disabled={isDisabled}
      className={classes.btn}
      startIcon={buttonIcon}
    >
      {buttonText}
    </Button>
  );
};

export const LinkButton = ({
  path,
  buttonText,
  color,
  buttonIcon = null,
  isDisabled = false,
  variant = "contained",
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      component={RouterLink}
      to={path}
      size="medium"
      disabled={isDisabled}
      variant={variant}
      startIcon={buttonIcon}
      color={color}
      className={classes.btn}
    >
      {buttonText}
    </Button>
  );
};

export const SubmitButton = ({
  buttonText = "Save",
  variant = "contained",
  color = "primary",
  isWorking = false,
  isDisabled = false,
  icon = null,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      size="medium"
      type="submit"
      variant={variant}
      color={color}
      disabled={isDisabled}
      className={classes.btn}
      startIcon={isWorking ? <CircularProgress size={20} /> : icon}
    >
      {buttonText}
    </Button>
  );
};

export const SubmitWithClickEventButton = ({
  onClick,
  buttonText = "Save",
  variant = "contained",
  color = "primary",
  isWorking = false,
  isDisabled = false,
  icon = null,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      size="medium"
      type="submit"
      variant={variant}
      color={color}
      disabled={isDisabled}
      className={classes.btn}
      startIcon={icon}
      onClick={onClick}
    >
      {isWorking ? <CircularProgress size={20} /> : buttonText}
    </Button>
  );
};

import { createMuiTheme } from "@material-ui/core/styles";

export const APP_THEME = createMuiTheme({
  body: {
    marginBottom: "20px",
  },
  container: {
    padding: "16px",
  },
  // palette: {
  //   type: "dark",
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "0",
    marginBottom: "20px",
  },
  typography: {
    h1: {
      fontSize: "1.6rem",
      marginBottom: "12px",
    },
    h2: {
      fontSize: "1.4rem",
      marginBottom: "12px",
    },
    h3: {
      fontSize: "1.2rem",
      marginBottom: "12px",
    },
    h4: {
      fontSize: "1.1rem",
      marginBottom: "12px",
    },
    h5: {
      fontSize: "1rem",
      marginBottom: "12px",
    },
  },
  a: {
    textDecoration: "none",
  },
});

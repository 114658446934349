import Typography from "@material-ui/core/Typography";

const PageSubHeading = ({ children }) => {
  return (
    <Typography component="h3" variant="h3">
      {children}
    </Typography>
  );
};

export default PageSubHeading;

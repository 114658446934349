import { EXERCISES } from "../data/exercises";

export const EXERCISE_FETCH_ALL = "EXERCISE_FETCH_ALL";

const EXERCISE_INITIAL_STATE = {
  categoryList: [],
  categoryDefinitions: {},
  exercises: {},
};

export const setExercises = () => {
  return {
    type: EXERCISE_FETCH_ALL,
    payload: {
      categoryList: EXERCISES.categoryList,
      categoryDefinitions: EXERCISES.categoryDefinitions,
      exercises: EXERCISES.allExercises,
    },
  };
};

export const exerciseReducer = (state = EXERCISE_INITIAL_STATE, action) => {
  switch (action.type) {
    case EXERCISE_FETCH_ALL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

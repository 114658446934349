import React from "react";
import { useForm, Controller } from "react-hook-form";

import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";

import { LinkButton, SubmitWithClickEventButton } from "../common/Buttons";

import { EXERCISES } from "../data/exercises";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  repCount: {
    maxWidth: "70px",
    textAlign: "center",
    marginTop: "0px",
    marginBottom: "0px",
  },
  lineItem: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  addRep: {
    color: green,
  },
  subtractRep: {
    color: red,
  },
  actionButtons: {
    margin: "16px 0",
  },
}));

const WorkoutCircuitForm = ({
  circuitNumber,
  totalCircuits,
  exerciseList,
  circuitData,
  onSubmit,
  isWorking,
}) => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, getValues, register } = useForm();

  const showPreviousButton = circuitNumber > 1;

  const alterValue = (elementName, isIncrement) => {
    const currentValue = parseInt(getValues(elementName));

    if (isIncrement) {
      setValue(elementName, currentValue + 1);
    } else {
      if (!isIncrement && currentValue > 0) {
        setValue(elementName, currentValue - 1);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div style={{ textAlign: "center" }}>
        <Chip
          label={`Circuit ${circuitNumber} of ${totalCircuits}`}
          color="secondary"
          variant="outlined"
        />
      </div>

      {exerciseList.map((exercise) => {
        const exerciseId = exercise.exerciseId;
        const exerciseDefinition = EXERCISES.allExercises[exerciseId];
        return (
          <div key={exerciseId}>
            <Grid container alignItems="center" className={classes.lineItem}>
              <Grid item xs={7}>
                <Typography>
                  {exercise.sequence}. {exerciseDefinition.name}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Grid container alignItems="center" justify="center">
                  <Grid
                    container
                    item
                    xs={2}
                    sm={2}
                    alignItems="center"
                    justify="center"
                  >
                    <IconButton onClick={() => alterValue(exerciseId, false)}>
                      <IndeterminateCheckBoxRoundedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Controller
                      name={exerciseId}
                      control={control}
                      defaultValue={String(circuitData[exerciseId])}
                      render={({ field }) => (
                        <InputBase
                          {...field}
                          type="number"
                          variant="standard"
                          fullWidth
                          size="small"
                          required
                          className={classes.repCount}
                          inputProps={{ style: { textAlign: "center" } }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container item xs={2} sm={2} justify="center">
                    <IconButton onClick={() => alterValue(exerciseId, true)}>
                      <AddBoxRoundedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </div>
        );
      })}
      <input type="hidden" value="" {...register("buttonClick")} />

      <Grid container className={classes.actionButtons}>
        <Grid
          container
          item
          xs={4}
          sm={4}
          alignItems="flex-start"
          justify="flex-start"
        >
          {showPreviousButton && (
            <SubmitWithClickEventButton
              variant="contained"
              color="primary"
              isDisabled={isWorking}
              isWorking={isWorking}
              onClick={() => setValue("buttonClick", "P")}
              buttonText="< Prev"
            />
          )}
        </Grid>
        <Grid container item xs={4} sm={4} alignItems="center" justify="center">
          <LinkButton path="/" buttonText="cancel" isDisabled={isWorking} />
        </Grid>
        <Grid
          container
          item
          xs={4}
          sm={4}
          alignItems="flex-end"
          justify="flex-end"
        >
          <SubmitWithClickEventButton
            variant="contained"
            color="primary"
            isDisabled={isWorking}
            isWorking={isWorking}
            onClick={() => setValue("buttonClick", "N")}
            buttonText={totalCircuits === circuitNumber ? "Save" : "Next >"}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default WorkoutCircuitForm;

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  smallCaps: {
    fontSize: "1.2rem",
    marginBottom: "12px",
  },
}));

const SmallCapsHeading = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" gutterBottom className={classes.smallCaps}>
      {children}
    </Typography>
  );
};

export default SmallCapsHeading;

import Typography from "@material-ui/core/Typography";

const PageHeading = ({ children }) => {
  return (
    <Typography component="h1" variant="h1">
      {children}
    </Typography>
  );
};

export default PageHeading;

import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../common/Spinner";
import PageHeading from "../common/PageHeading";
import PageSubHeading from "../common/PageSubHeading";
import WorkoutCircuitForm from "./WorkoutCircuitForm";

import { PATH_DASHBOARD } from "../paths";
import { setFeedbackMessage } from "../store/app-store";
import { createWorkout } from "../store/workout-store";
import { getShortUUID } from "../utils/general-helpers";

const Workout = () => {
  const reduxDispatch = useDispatch();

  const { myRoutines } = useSelector((state) => state.routine);
  const { routineId } = useParams();

  const [isLoading, setIsLoading] = useState(true); // when the component first loads
  const [isCircuitUpdating, setIsCircuitUpdating] = useState(false); // updating the circuit data
  const [isWorking, setIsWorking] = useState(false); // saving the workout
  const [redirect, setRedirect] = useState(null);

  const [routine, setRoutine] = useState({});
  const [currentCircuit, setCurrentCircuit] = useState(1);
  const [circuitData, setCircuitData] = useState({});

  useEffect(() => {
    if (myRoutines && Object.keys(myRoutines).length > 0) {
      const selectedRoutine = myRoutines[routineId];
      const initialCircuitData = {};

      for (let i = 0; i < selectedRoutine.circuits; i++) {
        const circuitExercises = {};

        selectedRoutine.exercises.forEach((exercise) => {
          circuitExercises[exercise.exerciseId] = exercise.reps;
        });

        initialCircuitData[i + 1] = circuitExercises;
      }
      setRoutine(selectedRoutine);
      setCircuitData(initialCircuitData);
      setIsLoading(false);
    }
  }, [routineId, myRoutines]);

  const onSubmit = (formData) => {
    const doSaveWorkout = async () => {
      try {
        setIsWorking(true);
        const workoutData = {
          workoutId: getShortUUID(),
          routineId: routine.id,
          routineName: routine.name,
          circuits: circuitData,
          exercises: myRoutines[routineId].exercises,
        };

        reduxDispatch(createWorkout(workoutData));
        setRedirect(PATH_DASHBOARD);
      } catch (err) {
        console.log(err);
        reduxDispatch(setFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    setIsCircuitUpdating(true);

    // extract which button was clicked and then remove it from the form data
    const nextOrPrev = formData["buttonClick"];
    delete formData["buttonClick"];

    const newCircuitData = { ...circuitData };
    newCircuitData[currentCircuit] = formData;
    setCircuitData(newCircuitData);

    if (nextOrPrev === "P" && currentCircuit > 1) {
      setCurrentCircuit(currentCircuit - 1);
    } else {
      if (currentCircuit === routine.circuits) {
        doSaveWorkout();
      } else {
        setCurrentCircuit(currentCircuit + 1);
      }
    }
    setIsCircuitUpdating(false);
  };

  if (!isLoading && !isWorking && !isCircuitUpdating && redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !isCircuitUpdating && (
        <>
          <PageHeading>{routine.name}</PageHeading>
          <PageSubHeading>Log Workout</PageSubHeading>
          <WorkoutCircuitForm
            totalCircuits={routine.circuits}
            circuitNumber={currentCircuit}
            exerciseList={routine.exercises}
            circuitData={circuitData[currentCircuit]}
            onSubmit={onSubmit}
            isWorking={isWorking}
          />
        </>
      )}
    </>
  );
};

export default Workout;

import React from "react";
import Alert from "@material-ui/lab/Alert";

import { useStyles } from "../utils/styles";

const ErrorMessage = ({ messageText, onDismissClick }) => {
  const styles = useStyles();

  return (
    <div className={styles.alert}>
      <Alert onClose={() => onDismissClick()} severity="error">
        {messageText}
      </Alert>
    </div>
  );
};

export default ErrorMessage;

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import DragHandle from "../common/DragHandle";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
    marginBottom: "8px",
    padding: "0px",
    backgroundColor: "#fafafa",
  },
  formInput: {
    margin: 0,
  },
}));

const DraggableExerciseCard = ({
  exercise,
  dragHandleProps,
  isDragging,
  value,
  onFieldChange,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <TextField
            id={exercise.id}
            name={exercise.id}
            label={exercise.name}
            variant="standard"
            margin="normal"
            size="small"
            className={classes.formInput}
            defaultValue={defaultValue}
            required
            onBlur={(e) => onFieldChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <div style={{ float: "right" }}>
            <DragHandle dragHandleProps={dragHandleProps} />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DraggableExerciseCard;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import { PATH_SIGN_IN } from "../paths";

const ProtectedRoute = ({ isSignedIn, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn) {
          return <Component {...props} {...rest} />;
        } else {
          return <Redirect to={PATH_SIGN_IN} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;

import React from "react";
import useIsMounted from "ismounted";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SuccessMessage = ({ messageText, onDismissClick }) => {
  const isMounted = useIsMounted();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (messageText) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageText]);

  const handleClose = (event, reason) => {
    if (open && isMounted.current) {
      onDismissClick();
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    }
  };

  if (messageText) {
    return (
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>
    );
  }
};

export default SuccessMessage;

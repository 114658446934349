import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PageHeading from "../common/PageHeading";
import ParagraphText from "../common/ParagraphText";
import ButtonGroup from "../common/ButtonGroup";

import { ActionButton, LinkButton } from "../common/Buttons";

import SelectRoutineExerciseList from "./SelectRoutineExerciseList";
import UpdateRoutineForm from "./UpdateRoutineForm";

import { setFeedbackMessage } from "../store/app-store";
import { updateRoutine, addRoutine } from "../store/routine-store";

import { PATH_DASHBOARD } from "../paths";
import Spinner from "../common/Spinner";

const DEFAULT_REP_COUNT = 10;
const DEFAULT_ROUTINE_DATA = {
  name: "",
  description: "",
  circuits: 3,
};

const UpdateRoutine = () => {
  const reduxDispatch = useDispatch();
  const { routineId } = useParams();
  const { myRoutines } = useSelector((state) => state.routine);

  const [pageTitle, setPageTitle] = useState("Add Routine");
  const [isWorking, setIsWorking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedExercises, setSelectedExercises] = useState([]);
  const [selectedExerciseReps, setSelectedExerciseReps] = useState({});
  const [routine, setRoutine] = useState(DEFAULT_ROUTINE_DATA);

  const [openPage, setOpenPage] = useState("A");
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    if (routineId) {
      const tmpRoutine = { ...myRoutines[routineId] }; // create a copy of the redux state object so we can mutate it
      setRoutine(tmpRoutine);

      setPageTitle(`Update ${tmpRoutine.name}`);

      const tmpExercises = tmpRoutine.exercises.map((ex) => ex.exerciseId);
      setSelectedExercises(tmpExercises);

      const tmpReps = {};

      tmpRoutine.exercises.forEach((e) => {
        tmpReps[e.exerciseId] = e.reps;
      });
      setSelectedExerciseReps(tmpReps);
    }
    setIsLoading(false);
  }, [routineId, myRoutines]);

  const handleExerciseToggle = (value) => () => {
    const currentIndex = selectedExercises.indexOf(value);
    const newChecked = [...selectedExercises];
    const newReps = { ...selectedExerciseReps };

    if (currentIndex === -1) {
      newChecked.push(value);
      newReps[value] = DEFAULT_REP_COUNT;
    } else {
      newChecked.splice(currentIndex, 1);
      delete newReps[value];
    }

    setSelectedExercises(newChecked);
    setSelectedExerciseReps(newReps);
  };

  const handleExerciseReOrder = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const newExerciseArray = Array.from(selectedExercises);
    newExerciseArray.splice(source.index, 1);
    newExerciseArray.splice(destination.index, 0, draggableId);
    setSelectedExercises(newExerciseArray);
  };

  const showSelectExercises = () => {
    setOpenPage("A");
  };

  const showUpdateForm = () => {
    setOpenPage("B");
  };

  const handleRoutineFieldChange = (elementId, value) => {
    const newRoutine = { ...routine };
    newRoutine[elementId] = value;
    setRoutine(newRoutine);
  };

  const handleRepFieldChange = (elementId, value) => {
    const newSelectedExerciseReps = { ...selectedExerciseReps };
    newSelectedExerciseReps[elementId] = value;
    setSelectedExerciseReps(newSelectedExerciseReps);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const doCreate = async () => {
      try {
        setIsWorking(true);

        const newRoutine = { ...routine, exercises: [] };

        selectedExercises.forEach((exerciseId) => {
          const item = {
            exerciseId: exerciseId,
            reps: selectedExerciseReps[exerciseId],
            sequence: selectedExercises.indexOf(exerciseId) + 1,
          };
          newRoutine.exercises.push(item);
        });

        if (routineId) {
          reduxDispatch(updateRoutine(routineId, newRoutine));
        } else {
          reduxDispatch(addRoutine(newRoutine));
        }

        setRedirect(PATH_DASHBOARD);
      } catch (err) {
        console.log(err);
        reduxDispatch(setFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    doCreate();
  };

  if (!isWorking && redirect) {
    return <Redirect to={redirect} />;
  }

  if (!isLoading) {
    return (
      <>
        {openPage === "A" && (
          <>
            <PageHeading>
              {pageTitle}
              <br />
              Step 1 of 2
            </PageHeading>
            <ParagraphText variant="body2">
              Select the exercises you want to include in this routine.
            </ParagraphText>
            <ParagraphText variant="body2">
              You have {selectedExercises.length}{" "}
              {selectedExercises.length === 1 ? "exercise" : "exercises"} so
              far.
            </ParagraphText>
            <SelectRoutineExerciseList
              selectedExercises={selectedExercises}
              handleToggle={handleExerciseToggle}
            />
            <ButtonGroup>
              <LinkButton path="/" buttonText="cancel" />
              <ActionButton
                buttonText="Next >"
                onClickEvent={showUpdateForm}
                isDisabled={0 === selectedExercises.length || isWorking}
              />
            </ButtonGroup>
          </>
        )}
        {openPage === "B" && (
          <>
            <PageHeading>
              {pageTitle}
              <br />
              Step 2 of 2
            </PageHeading>
            <UpdateRoutineForm
              selectedExercises={selectedExercises}
              onSubmit={handleFormSubmit}
              dragEnded={handleExerciseReOrder}
              onBackClick={showSelectExercises}
              onRepChange={handleRepFieldChange}
              onFieldChange={handleRoutineFieldChange}
              routineData={routine}
              repData={selectedExerciseReps}
              isWorking={isWorking}
            />
          </>
        )}
      </>
    );
  }

  return <Spinner />;
};

export default UpdateRoutine;

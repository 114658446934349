import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";

import { STATE_FINISHED, STATE_WORKING } from "../store/store-constants";
import Spinner from "../common/Spinner";
import SmallCapsHeading from "../common/SmallCapsHeading";
import { LinkButton } from "../common/Buttons";
import { PATH_ACTIVITY_LOG } from "../paths";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  statContainer: {
    padding: "16px",
  },
  mainStat: {
    fontSize: "3.5rem",
    textAlign: "center",
    marginTop: "2px",
    marginBottom: "6px",
  },
  statInnerBlock: {
    textAlign: "center",
  },
}));

const WorkoutStats = () => {
  const { stats, workingState } = useSelector((state) => state.workout);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SmallCapsHeading>Workouts</SmallCapsHeading>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <Paper
            variant="outlined"
            className={classes.statContainer}
            component="div"
          >
            {workingState === STATE_WORKING && <Spinner />}
            {workingState === STATE_FINISHED && stats && stats.thisWeek && (
              <div className={classes.statInnerBlock}>
                <Typography variant="overline" color="secondary">
                  This Week
                </Typography>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.mainStat}
                  color="secondary"
                >
                  {stats.thisWeek.workouts}
                </Typography>

                <LinkButton
                  buttonText="view log"
                  variant="text"
                  buttonIcon={
                    <FormatListBulletedOutlinedIcon fontSize="small" />
                  }
                  path={`${PATH_ACTIVITY_LOG}?ts=tw`}
                />
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Paper
            variant="outlined"
            className={classes.statContainer}
            component="div"
          >
            {workingState === STATE_WORKING && <Spinner />}
            {workingState === STATE_FINISHED && stats && stats.thisMonth && (
              <div className={classes.statInnerBlock}>
                <Typography variant="overline" color="secondary">
                  This Month
                </Typography>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.mainStat}
                  color="secondary"
                >
                  {stats.thisMonth.workouts}
                </Typography>

                <LinkButton
                  buttonText="view log"
                  variant="text"
                  buttonIcon={
                    <FormatListBulletedOutlinedIcon fontSize="small" />
                  }
                  path={`${PATH_ACTIVITY_LOG}?ts=tm`}
                />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default WorkoutStats;
